<template>
  <v-card>
      <v-card-title class="primary--text text--darken-4 text-h5">เพิ่มแบบฟอร์มมาตรฐาน<v-spacer/><v-btn icon @click="$emit('onClose')"><v-icon>mdi-close</v-icon></v-btn></v-card-title>
      <v-card-text>
          <v-row>
               <v-col cols="12">
                     <v-card flat outlined>
                          <v-card-text>
           <v-form ref="form" v-model="valid" lazy-validation>
          <v-row>
            <v-spacer/>
              <v-col cols="12" md="3">
              <TextField :rules="formTypeNameRules" v-model="formTypeName" dense :title="'ชื่อแบบฟอร์ม'"/>
            </v-col>
             <v-col cols="12" md="3">
              <TextField :rules="formTypeIdRules" v-model="formTypeId" dense :title="'ประเภทแบบฟอร์ม'"/>
            </v-col>
              <v-col cols="12" md="3">
              <TextField v-model="version" dense :title="'เวอร์ชั่น'"/>
            </v-col>
             <v-col cols="12" md="3">
               <label class="text-title-4 natural--text text--darken-4">ดำเนินการ
               <v-btn color="primary"  block @click="createMaster()"  :loading="loading">บันทึก</v-btn>
               </label>
            </v-col>
            </v-row></v-form></v-card-text>
                     </v-card>
               </v-col>
              <v-col cols="12">
           <v-card flat outlined>
               <v-card-text>
  <vue-json-editor v-model="fields" :show-btns="false" :expandedOnStart="true" @json-change="onJsonChange"  :mode="'code'" ></vue-json-editor>
               </v-card-text>
           </v-card>
              </v-col>
          </v-row>
      </v-card-text>
  </v-card>
</template>

<script>
import vueJsonEditor from 'vue-json-editor'
import { postMasterFormType } from '@/api/'
import TextField from '@/components/common/TextField'

export default {
  components: {
    vueJsonEditor,
    TextField
  },
  data () {
    return {
      loading: false,
      masterItem: [],
      fields: {},
      headers: [
        { text: 'ประเภทแบบฟอร์ม', value: 'formTypeId', class: 'primary lighten-5' },
        { text: 'ชื่อแบบฟอร์ม', value: 'formTypeName', class: 'primary lighten-5' }
      ],
      formTypeId: '',
      formTypeName: '',
      version: '1',
      formTypeNameRules: [
        v => !!v || 'Data is required'
      ],
      formTypeIdRules: [
        v => !!v || 'Data is required'
      ],
      valid: false
    }
  },
  methods: {
    onJsonChange (value) {
      this.fields = value
      // this.$emit('change', value)
      // console.log('value:', value)
    },
    createMaster () {
      const newFormType = {
        formTypeId: this.formTypeId,
        formTypeName: this.formTypeName,
        formTypeNameEn: this.formTypeName,
        version: this.version,
        versionDate: new Date(),
        schema: {
          fields: this.fields
        }
      }
      postMasterFormType(newFormType, message => {
        if (message.data.code === 1) {
          this.$emit('onClose')
        }
      }, error => {
        console.log(error)
      })
    }

  }
}
</script>

<style lang="scss" scoped>
::v-deep .jsoneditor-vue {
  height: 500px;
}
::v-deep .editor {
    height: 500px;
}
</style>
